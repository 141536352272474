/** @jsx jsx */
import PropTypes from "prop-types"

// Utilities
import kebabCase from "lodash/kebabCase"
import { Styled, css, jsx } from "theme-ui";

// Components
import Layout from "gatsby-theme-blog/src/components/layout"
import SEO from "gatsby-theme-blog/src/components/seo"
import PostFooter from "gatsby-theme-blog/src/components/post-footer"
import { Link } from "gatsby"
import PostTitle from "gatsby-theme-blog/src/components/post-title";

const Tags = ({ location, data }) => {
  const { site, allMdx } = data
  const { group } = allMdx
  const tagHeader = `All Tags`

  return (
    <Layout location={location} title={site.siteMetadata.title}>
        <SEO
            title={tagHeader}
        />
        <main>
          <header>
            <PostTitle>{tagHeader}</PostTitle>
          </header>
          <section>
            <Styled.ul>
              {group.map(tag => (
                <Styled.li key={tag.fieldValue} css={css({
                  mb: 10,
                })}>
                  <Styled.a
                    as={Link}
                    sx={{
                      textDecoration: `none`,
                    }}
                    to={`/tags/${kebabCase(tag.fieldValue)}/`}>
                    {tag.fieldValue} ({tag.totalCount})
                  </Styled.a>
                </Styled.li>
              ))}
            </Styled.ul>
          </section>
        </main>
        <PostFooter />
    </Layout>    
  )
}

export default Tags

Tags.propTypes = {
  data: PropTypes.shape({
    allMdx: PropTypes.shape({
      group: PropTypes.arrayOf(
        PropTypes.shape({
          fieldValue: PropTypes.string.isRequired,
          totalCount: PropTypes.number.isRequired,
        }).isRequired
      ),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }),
    }),
  }),
}