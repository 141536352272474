import { graphql } from "gatsby"
import TagsPage from "../gatsby-theme-blog/components/tags"

export default TagsPage

export const tagsQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(limit: 2000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`